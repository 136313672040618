<template>
  <section class="content">
    <form role="form" @submit="submitForm">
      <input type="hidden" name="img_code" value="" v-model="form.img_code" id="img_code" />
      <div class="card card-default" ref="formDialog">
        <!-- <form role="form" @submit="submitForm"> -->
        <div class="card-header">
          <h3 class="card-title">{{ formTitle }}</h3>

          <div class="card-tools">
            <button
              type="button"
              class="btn btn-tool"
              data-card-widget="collapse"
            >
              <i class="fas fa-minus"></i>
            </button>
          </div>
        </div>
        <!-- /.card-header -->
        <div class="card-body">
          <p v-if="errors.length" class="alert alert-danger alert-dismissible fade show" role="alert">
                <b>Please correct the following error(s):</b>
                <ul>
                  <li v-for="error in errors" v-bind:key="error">{{ error }}</li>
                </ul>
                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
              </p>
          <div class="row">
            <div class="col-md-6 form-group">
              <label class="control-label">NAMA</label>
              <input
                id="name"
                class="form-control"
                v-model="form.name"
                type="text"
                required="required"
                name="name"
              />
            </div>
            <div class="col-md-6 form-group">
              <label class="control-label">DESKRIPSI</label>
              <input
                id="description"
                class="form-control"
                v-model="form.description"
                type="text"
                name="description"
              />
            </div>
            <div class="col-md-6 form-group">
              <label class="control-label">DIMENSI</label>
              <input
                id="dimensi"
                class="form-control"
                v-model="form.dimensi"
                type="text"
                name="dimensi"
                placeholder="p x l x t"
              />
            </div>
            <div class="col-md-6 form-group">
              <label class="control-label">BRAND</label>
              <input
                id="brand"
                class="form-control"
                v-model="form.brand"
                type="text"
                name="brand"
              />
            </div>
            <div class="col-md-6 form-group">
              <label class="control-label">HARGA BELI</label>
              <input
                id="harga_beli"
                class="form-control"
                v-model="form.harga_beli"
                type="number"
                name="harga_beli"
              />
            </div>
            <div class="col-md-6 form-group">
             <label for="inputFile">Gambar</label>
              <div class="custom-file">
                  <input type="file" class="custom-file-input inputfile" id="inputFile"  name="file" @change="uploadFoto(this)">
                  <label class="custom-file-label" for="inputFile">Silahkan upload gambar</label>
              </div>
             </div>
          </div>
        </div>
        <!-- /.card-body -->
        <div class="card-footer">
          <button type="submit" class="btn btn-primary">Simpan</button>
        </div>
        <!-- </form> -->
      </div>
    </form>
    <!-- /.card -->
    <div class="table-responsive">
      <table class="table table-hover" ref="tblmaterial" style="width: 100%;">
        <thead>
          <tr>
            <th>CODE</th>
            <th>NAMA PRODUK</th>
            <th>DESKRIPSI</th>
            <th>FOTO</th>
          </tr>
        </thead>
        <tbody @click="handleClick"></tbody>
      </table>
    </div>
  </section>
  <!-- /.content -->
</template>

<script>
import { createTable, authFetch } from "@/libs/hxcore";
import Swal from "sweetalert2";
import $ from "jquery";

export default {
  name: "",
  data() {
    return {
      errors: [],
      method: "POST",
      roles: "",
      formTitle: "Form Material",
      form: {
        name: '',
        description: '',
        dimensi: '',
        brand: '',
        img_code: '',
        harga_beli: '',
        unit_id: '',
      },
      form_data: "",
    };
  },
  watch:{
    $route (to, from){
      if(to.path === "/material/"+1 || to.path === "/material/"+2 || to.path === "/material/"+3 || to.path === "/material/"+4 || to.path === "/material/"+5)
      {
        this.table.api().ajax.reload();
      }
    }
  },
  created: function () {
    this.roles = this.$route.meta.roles;
    console.log("load initial data", this.$route);
  },
  methods: {
    handleClick(e) {
      if (e.target.matches(".link-role")) {
        this.$router.push({ path: "/permit/" + e.target.dataset.id });
        return false;
      }
    },
    urlForm(url, method, data){
		const e = this.$refs;
		Swal.fire({
				title: "Simpan?",
				showCancelButton: true,
				confirmButtonText: `Ya`,
				cancelButtonText: "Tidak",
		}).then((result) => {
			if (result.isConfirmed) {
			authFetch(url, {
					method: method,
					body: data,
			})
				.then((res) => {
				return res.json();
				})
				.then((js) => {
				if (js.success) {
						Swal.fire("Proses Berhasil", ``, "success");
						this.table.api().ajax.reload();
            this.form = {};
				} else {
						Swal.fire("Proses gagal", ``, "error");
						}
				});
			}
		});
	},
    submitForm: function (ev) {
      const e = this.$refs;
      let self = this;

      self.form.unit_id = self.$route.params.id;
      var data = Object.keys(self.form)
        .map(
          (key) =>
            encodeURIComponent(key) +
            "=" +
            encodeURIComponent(self.form[key])
        )
        .join("&");
      var urlSubmit = "/management/material";
      if (self.method == "PUT")
          urlSubmit = "/management/material/" + self.form.id;
      var file_data = $('#inputFile').prop('files');

      if (file_data.length == 0) {
        console.log("upload kosong");
        self.urlForm(urlSubmit, self.method, data);
      } else {
        self.doUpload().then((res) => {
			if (res.status === 201) {
			} else if (res.status === 400) {}
			return res.json();
        }).then(js => {
			console.log(js)
			self.errors = [];
			if (!js.status) {
				console.log(js.details)
				for (var key in js.details) {
					if (js.details.hasOwnProperty(key)) {
						self.errors.push(js.details[key])
					}
				}
				return;
			} else {
				if (js.message[0]) {
					self.form.img_code = js.message[0].id;
				}
        self.form.unit_id = self.$route.params.id;
				var data2 = Object.keys(self.form).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(self.form[key])).join('&');
				self.urlForm(urlSubmit, self.method, data2);
			}
    });
      }
      ev.preventDefault();
    },
    uploadFoto: function (file) {
      var file_data = $("#inputFile").prop("files");
      var form_data = new FormData();
      var fileInput = $("#inputFile")[0];
      $.each(fileInput.files, function (k, file) {
        form_data.append("file", file);
      });
      form_data.append("subdir", "/material/");
      form_data.append("id", this.form.id);
      this.form_data = form_data;
      return false;
    },
    doUpload: function () {
      return authFetch("/management/material/upload_data", {
        method: "POST",
        body: this.form_data,
        headers: {
          "Content-Type": null,
        },
      });
    },
  },
  mounted() {
    const e = this.$refs;
    let self = this;
    this.table = createTable(e.tblmaterial, {
      title: "",
      roles: this.$route.params.roles,
      ajax: "/management/material",
      columnDefs: [{ className: "text-center", targets: 3 }],
      processing: true,
      scrollX: true,
      columns: [
        { data: "kode_barang" },
        { data: "name" },
        { data: "description" },
        { data: "img_code" },
      ],
      paramData: function(d) {
        d.unit_id = self.$route.params.id;
			},
      filterBy: [0, 1],
      rowCallback: function (row, data) {
        console.log(data.img_code+' '+data.file_path)
        if (!data.img_code || data.img_code == '0') {
          $("td:eq(3)", row).html("-");
        } else {
          if (data.file_path) {
            $("td:eq(3)", row).html(
              '<img width="100px;" src="' +
                window.$apiUrl + "/file/image/" + data.img_code + '" v-if="todo.file_path">'
            );
          }
        }
      },
      buttonClick: (evt) => {
        if (evt.role == "create") {
          self.form = {};
          self.method = "POST";
          self.errors = [];
          self.formTitle = "Tambah Material";
          // $(e.formDialog).modal("show");
          $(e.formDialog).CardWidget("expand");
        } else if (evt.role == "update" && evt.data) {
          self.form = evt.data;
          self.method = "PUT";
          self.errors = [];
          self.formTitle = "Edit Material";
          // $(e.formDialog).modal("show");
          $(e.formDialog).CardWidget("expand");
        } else if (evt.role == "delete" && evt.data) {
          // self.form = evt.data;
          console.log(evt);
          Swal.fire({
            title: "Hapus data?",
            icon: "question",
            denyButtonText: '<i class="fa fa-times"></i> Hapus',
            showCancelButton: true,
            showDenyButton: true,
            showConfirmButton: false,
          }).then((result) => {
            if (result.isDenied) {
              authFetch("/management/material/" + evt.data.id, {
                method: "DELETE",
                body: "id=" + evt.data.id,
              })
                .then((res) => {
                  return res.json();
                })
                .then((js) => {
                  this.table.api().ajax.reload();
                });
            }
          });
        }
      },
    });
  },
};
</script>
<style scoped>
/* img {
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 5px;
  width: 150px;
} */
</style>
